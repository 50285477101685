<template>
    <v-app>
        <iframe :src="iframeUrl"
            width="100%" height="100%"></iframe>
    </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
  },
  data() {
    return {
      iframeUrl: "",
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
  },
  mounted() {
    this.iframeUrl = `https://zobaze.retool.com/embedded/public/d9f11ecd-6c76-4358-8a2f-d630c7411432?email=${this.user.email}`;
  },
};
</script>
